export async function fetchAllHydra(axios, url) {
  let results = []
  let nextUrl = url
  do {
    const response = await axios.get(nextUrl)
    results = [
      ...results,
      ...response.data['hydra:member'],
    ]

    nextUrl = response.data['hydra:view']['hydra:next'] ?? null
  } while (nextUrl !== null)

  return results
}

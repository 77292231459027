<template>
  <v-autocomplete
    v-model="taxonomyId"
    :label="'Taxonomie'"
    :items="currentTaxonomies"
    :error-messages="error"
    hide-details
    clearable
    dense
    outlined
    small-chips
    deletable-chips
    :multiple="multiple"
    item-text="name"
    item-value="id"
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'
import { mapState } from 'vuex'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: () => [],
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
    attribute: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['taxonomies']),
    taxonomyId: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
    currentTaxonomies () {
      const ret = []
      Object.values(this.taxonomies).forEach(taxonomies => {
        taxonomies.forEach(t => {
          if (t.attribute.id === this.attribute) {
            ret.push(t)
          }
        })
      })

      return ret
    },
  },
}
</script>

<template>
  <img
    width="24"
    height="24"
    class="mr-2"
    :src="getProviderIcon(provider)"
    :alt="$t('enum.providers.' + provider)"
    :title="$t('enum.providers.' + provider)"
  >
</template>
<script>
import shipment from '@/mixins/shipment'

export default {
  name: 'ProviderIcon',
  mixins: [ shipment ],
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>

<template>
  <v-select
    v-model="stateValue"
    :items="values"
    :dense="dense"
    hide-details
    prepend-inner-icon="mdi-home-account"
    label="Gestion Interne"
    clearable
    outlined
    small-chips
    chips
    deletable-chips
    item-text="label"
    item-value="value"
  />
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: null,
    },
    dense: Boolean,
  },
  data() {
    return {
      values: [
        {
          label: this.$i18n.t('views.shipment.enum.internal.yes'),
          value: 'true',
        },
        {
          label: this.$i18n.t('views.shipment.enum.internal.no'),
          value: 'false',
        },
      ],
    }
  },
  computed: {
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <v-chip
    pill
  >
    {{ $t('views.taxonomy.enum.type_of_store.'+ typeOfStore.toUpperCase()) }}
  </v-chip>
</template>
<script>

export default {
  name: 'TypeOfStoreChip',
  props: {
    typeOfStore: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>

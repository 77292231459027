'use strict'

import axios from 'axios'
import router from '../router'
import store from '../store'
import Vue from 'vue'

axios.defaults.headers.post['Content-Type'] = 'application/json'

let config = {
  baseURL: process.env.VUE_APP_SC_API_BASE_URL || '',
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const token = store.state.token
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Accept-Language'] = 'fr,en'

    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    // Do something with response error
    if (error && error.response && error.response.status === 401) {
      store.commit('logout')
      router.push({ name: 'Login' })
    }

    return Promise.reject(error)
  }
)

_axios.CancelToken = axios.CancelToken
_axios.isCancel = axios.isCancel

Plugin.install = function(Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin

<template>
  <v-text-field
    v-model="stock"
    :label="inputLabel($t('views.products.forms.input.stock'), true)"
    :error-messages="error"
    type="number"
    hide-details
    clearable
    dense
    outlined
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  computed: {
    stock: {
      get () { return this.value },
      set (value) { this.$emit('input', +value) },
    },
  },
}
</script>

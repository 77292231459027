<template>
  <v-autocomplete
    v-model="storeId"
    :label="inputLabel($t('views.products.forms.input.store'), required)"
    :items="stores"
    :loading="isLoading"
    :search-input.sync="search"
    :error-messages="error"
    :prepend-inner-icon="filter ? 'mdi-store-outline' : ''"
    hide-details
    item-text="name"
    item-value="id"
    clearable
    outlined
    dense
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    filter: Boolean,
    dense: Boolean,
    prependIcon: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,
      fromSearch: false,
      keepEntries: false,
      entries: [],
    }
  },
  computed: {
    storeId: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
    stores () {
      return this.entries
    },
  },
  watch: {
    search (val) {
      if (this.keepEntries) {
        this.keepEntries = false

        return
      }
      this.clearEntries()
      if (!val) {
        return
      }
      this.isLoading = true
      this.fetchEntriesDebounced(val)
    },

    async value (id, prevId) {
      if (prevId !== null) {
        return
      }

      this.keepEntries = true

      await this.$axios.get('stores/' + id + '/')
        .then(response => {
          this.entries = [
            {
              id: response.data.id,
              name: response.data.name,
            },
          ]
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => this.isLoading = false)
    },
  },
  methods: {
    clearEntries() {
      this.entries = []
    },
    fetchEntriesDebounced(val) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries(val)
      }, 500)
    },
    fetchEntries(val) {
      // Lazily load input items
      this.$axios.get('stores?name=' + val)
        .then(response => {
          let res = response.data['hydra:member']

          this.entries = res.map(
            store => {
              return {
                id: store.id,
                name: store.name,
              }
            }
          )
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => this.isLoading = false)
    },
  },
}
</script>

<template>
  <v-autocomplete
    v-model="productId"
    :items="products"
    :label="inputLabel($t('views.products.forms.input.product'), required)"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    dense
    hide-details
    item-text="name"
    item-value="id"
    outlined
    prepend-inner-icon="mdi-wardrobe-outline"
    small-chips
    @click:clear="keepEntries = false"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        size="45"
        color="grey lighten-1"
      >
        <v-img
          :src="item.photo_url"
          alt="item.title"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
        <v-list-item-subtitle v-text="item.store_name" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  data() {
    return {
      isLoading: false,
      search: null,
      fromSearch: false,
      keepEntries: false,
      entries: [],
    }
  },
  computed: {
    productId: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
    products () {
      return this.entries
    },
  },
  watch: {
    search (val) {
      if (this.keepEntries) {
        this.keepEntries = false
        
        return
      }
      this.clearEntries()
      if (!val) {
        return
      }
      this.isLoading = true
      this.fetchEntriesDebounced(val)
    },
    
    async value (id, prevId) {
      if (prevId !== null) {
        return
      }
      
      this.keepEntries = true
      
      await this.$axios.get('/v3/products/' + id + '/')
        .then(response => {
          this.entries = [
            {
              id: response.data.id,
              name: response.data.title + ' (' + response.data.reference + ')',
              title: response.data.title,
              photo_url: response.data.photos[0]?.photo?.src?.thumbnail ?? null,
              store_name: response.data.store.name,
            },
          ]
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => this.isLoading = false)
    },
  },
  methods: {
    clearEntries() {
      this.entries = []
    },
    fetchEntriesDebounced(val) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries(val)
      }, 500)
    },
    fetchEntries(val) {
      // Lazily load input items
      this.$axios.get('/v3/products?full_reference=' + val)
        .then(response => {
          let res = response.data['hydra:member']
          this.entries = res.map(
            product => {
              return {
                id: product.id,
                name: product.title + ' (' + product.search_references + ')',
                title: product.title,
                photo_url: product.photos[0]?.photo?.src?.thumbnail ?? null,
                store_name: product.store.name,
              }
            }
          )
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => this.isLoading = false)
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @click:outside="cancel"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title
        :color="options.color"
        dense
        flat
      >
        {{ title }}
      </v-card-title>
      <v-card-text
        v-show="!!message"
        class="pa-4"
      >
        <v-alert
          type="info"
          border="left"
          outlined
          prominent
          dense
        >
          {{ message }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn
          outlined
          color="red darken-2"
          @click="cancel"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="agree"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>
          {{ $t('forms.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    message: null,
    options: {
      color: 'primary',
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>

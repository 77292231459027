import EventBus from '@/plugins/event-bus'

const snackbarMixin = {
  methods: {
    snackbarSuccess (message, link = null) {
      EventBus.$emit('SNACKBAR', { color: 'green', message: message, link: link })
    },
    snackbarError (message) {
      EventBus.$emit('SNACKBAR', { color: 'error', message: message })
    },
    snackbarWarning (message) {
      EventBus.$emit('SNACKBAR', { color: 'warning', message: message })
    },
  },
}

export default snackbarMixin

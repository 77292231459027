<template>
  <v-card
    class="mb-4"
  >
    <v-card-title v-if="showTitle">
      {{ $t('views.products.forms.labels.curation') }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-rating
            v-model="scoreValue"
            :label="$t('views.products.forms.input.score')"
            background-color="indigo lighten-3"
            color="indigo"
            large
            length="3"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="favoriteValue"
            prepend-icon="mdi-heart-outline"
            :label="$t('views.products.forms.input.favorite')"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="wornPhotosValue"
            prepend-icon="mdi-account-box-outline"
            :label="$t('views.products.forms.input.worn_photos')"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="inImparfaiteWarehouseValue"
            prepend-icon="mdi-warehouse"
            :label="$t('views.products.forms.input.in_imparfaite_warehouse')"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    score: {
      type: Number,
      required: false,
      default: null,
    },
    favorite: {
      type: Boolean,
      required: false,
      default: null,
    },
    wornPhotos: {
      type: Boolean,
      required: false,
      default: null,
    },
    inImparfaiteWarehouse: {
      type: Boolean,
      required: false,
      default: null,
    },
    showTitle: Boolean,
  },
  computed: {
    scoreValue: {
      get () { return this.score  },
      set (value) { this.$emit('update:score', value) },
    },
    favoriteValue: {
      get () { return this.favorite  },
      set (value) { this.$emit('update:favorite', value) },
    },
    wornPhotosValue: {
      get () { return this.wornPhotos },
      set (value) { this.$emit('update:wornPhotos', value) },
    },
    inImparfaiteWarehouseValue: {
      get () { return this.inImparfaiteWarehouse },
      set (value) { this.$emit('update:inImparfaiteWarehouse', value) },
    },
  },
}
</script>

<template>
  <v-autocomplete
    v-model="family"
    :label="inputLabel($t('views.products.forms.input.family'), required)"
    :items="familyItems"
    :error-messages="error"
    hide-details
    clearable
    dense
    outlined
    item-text="name"
    item-value="id"
    @change="$emit('update:error', null)"
  >
    <template v-slot:selection="{ attr, on, item }">
      <span v-text="item.name" />
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo"
        class="text-h5 font-weight-light white--text"
      >
        {{ item.name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '@/mixins/input'
import { mapState } from 'vuex'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  computed: {
    ...mapState(['families']),
    family: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
    familyItems () {
      return this.families.map(v => {
        return {
          id: v,
          name: this.$i18n.t(`views.products.enum.family.${v}`),
        }
      })
    },
  },
}
</script>

<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :href="href"
    :target="target"
    class="ma-2"
    link
    outlined
    small
  >
    <v-icon
      v-if="icon"
      :left="text !== null"
      small
    >
      {{ icon }}
    </v-icon>
    <template v-if="text">
      {{ text }}
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonLink',
  props: {
    icon: { type: String, required: false, default: null  },
    text: { type: String, required: false, default: null },
    color: { type: String, required: false, default: 'blue lighten-1' },
    href: { type: String, required: true },
    target: { type: String,  required: false, default: '_blank' },
    disabled: { type: Boolean, required:false, default: false },
  },
}
</script>

<style scoped>

</style>

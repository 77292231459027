<template>
  <v-autocomplete
    v-model="taxonomyKind"
    :label="'Type'"
    :items="taxonomyKinds"
    :error-messages="error"
    hide-details
    clearable
    dense
    outlined
    small-chips
    deletable-chips
    :multiple="multiple"
    item-text="id"
    item-value="id"
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'
import { mapState } from 'vuex'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: () => [],
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['taxonomies']),
    taxonomyKinds () {
      return Object.keys(this.taxonomies).map(kind => ({ id: kind }))
    },
    taxonomyKind: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>

<template>
  <v-select
    v-model="stateValue"
    :items="providers"
    :dense="dense"
    hide-details
    prepend-inner-icon="mdi-dolly"
    label="Logisticien"
    clearable
    outlined
    multiple
    small-chips
    deletable-chips
    item-text="label"
    item-value="value"
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-icon>
          <ProviderIcon :provider="item.value" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import ProviderIcon from '@/components/base/Shipment/ProviderIcon.vue'

export default {
  components: { ProviderIcon },
  props: {
    state: {
      type: Array,
      default: () => ([]),
    },
    dense: Boolean,
  },
  data() {
    return {
      providers: [
        { label: 'Sendcloud', value: 'SENDCLOUD' },
        { label: 'Wing (B2B)', value: 'WING_B2B' },
        { label: 'Externe', value: 'EXTERNAL' },
      ],
    }
  },
  computed:{
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <span v-if="trackingNumber">
    {{ trackingNumber }}
    <a
      v-if="trackingUrl"
      :href="trackingUrl"
      target="_blank"
    >
      <v-icon
        small
        color="blue lighten-1"
      >mdi-open-in-new</v-icon>
    </a>
  </span>
</template>
<script>

export default {
  name: 'TrackingNumberLink',
  props: {
    trackingNumber: {
      type: String,
      default: null,
    },
    trackingUrl: {
      type: String,
      default: null,
    },
  },
}
</script>
<style scoped>

</style>

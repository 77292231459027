<template>
  <v-chip
    pill
  >
    <v-avatar left>
      <v-img
        :src="getProviderIcon(provider)"
        :alt="$t('enum.providers.' + provider)"
        :title="$t('enum.providers.' + provider)"
      />
    </v-avatar>
    {{ $t('enum.providers.' + provider) }}
  </v-chip>
</template>
<script>
import shipment from '@/mixins/shipment'

export default {
  name: 'ProviderChip',
  mixins: [ shipment ],
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>

<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    exact
    active-class="white--text"
    @click="onClick()"
  >
    <v-list-item-icon
      v-if="text"
      class="v-list-item__icon--text"
      v-text="computedText"
    />

    <v-list-item-icon>
      <v-icon
        v-if="item.icon"
        v-text="item.icon"
      />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title
        v-if="!item.type"
        v-text="item.title"
      />
      <v-chip
        v-if="item.type==='label'"
        depressed
        small
      >
        {{ item.title }}
      </v-chip>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from 'vuetify/lib/mixins/themeable'

export default {
  name: 'Item',

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        type: undefined,
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
        onClick: function () {
        },
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedText() {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach(val => {
        text += val.substring(0, 1)
      })

      return text
    },
    href() {
      return this.item.href || (!this.item.to ? '#' : undefined)
    },
  },
  methods: {
    onClick() {
      if (this.item.onClick) {
        this.item.onClick()
      }
    },
  },
}
</script>
<style scoped lang="scss">
    .v-list-item__content {
        flex-wrap: nowrap;
        > * {
            flex: initial;
        }
    }
</style>

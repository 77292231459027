<template>
  <v-card
    class="mb-4"
  >
    <v-card-title v-if="showTitle">
      {{ $t('views.products.forms.labels.texts') }}
    </v-card-title>
    
    <v-card-text class="py-1">
      <v-icon
        v-if="hasError()"
        color="red"
      >
        mdi-alert-circle
      </v-icon>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <template v-if="currentLanguage === 'default'">
              {{ $t('views.products.forms.labels.translations.default', {language: 'Appli Vendeur',}) }}
            </template>
            <template v-else-if="currentLanguage">
              {{ $t('views.products.forms.labels.translations.' + currentLanguage) }}
            </template>
            <template v-else>
              {{ $t('views.products.forms.labels.languages') }}
            </template>
            <v-icon right>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            key="default"
            :active="currentLanguage === 'default'"
            @click="currentLanguage = 'default'"
          >
            <v-list-item-title>
              {{ $t('views.products.forms.labels.translations.default', {language: 'Appli Vendeur',}) }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon
                v-if="hasError('default')"
                color="red darken-2"
              >
                mdi-alert-circle
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-for="language in languages"
            :key="language"
            :active="currentLanguage === language"
            @click="currentLanguage = language"
          >
            <v-list-item-title>
              {{ $t('views.products.forms.labels.translations.' + language) }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon
                v-if="hasError(language)"
                color="red darken-2"
              >
                mdi-alert-circle
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-text>
    <v-card-text class="py-1">
      <template v-if="currentLanguage === 'default'">
        <v-text-field
          v-model="titleValue"
          :label="inputLabel($t('views.products.forms.input.content_title'), true)"
          :error-messages="errors.title"
          outlined
          dense
          @change="change"
        />
        <v-textarea
          v-model="descriptionValue"
          :label="inputLabel($t('views.products.forms.input.content_description'), true)"
          :error-messages="errors.description"
          outlined
          dense
          @change="change"
        />
        <v-textarea
          v-model="sizingValue"
          :label="inputLabel($t('views.products.forms.input.content_sizing'))"
          :error-messages="errors.sizing"
          rows="1"
          outlined
          dense
          @change="change"
        />
        <v-textarea
          v-model="defectsValue"
          :label="inputLabel($t('views.products.forms.input.content_defects'))"
          :error-messages="errors.defects"
          rows="1"
          outlined
          dense
          @change="change"
        />
      </template>
      <template v-else-if="currentLanguage">
        <v-text-field
          v-model="currentTranslationTitleValue"
          :label="inputLabel($t('views.products.forms.input.content_title'))"
          :error-messages="currentTranslationErrors.title"
          outlined
          dense
          @change="changeTranslationErrors"
        />
        <v-textarea
          v-model="currentTranslationDescriptionValue"
          :label="inputLabel($t('views.products.forms.input.content_description'))"
          :error-messages="currentTranslationErrors.description"
          outlined
          dense
          @change="changeTranslationErrors"
        />
        <v-textarea
          v-model="currentTranslationSizingValue"
          :label="inputLabel($t('views.products.forms.input.content_sizing'))"
          :error-messages="currentTranslationErrors.sizing"
          rows="1"
          outlined
          dense
          @change="changeTranslationErrors"
        />
        <v-textarea
          v-model="currentTranslationDefectsValue"
          :label="inputLabel($t('views.products.forms.input.content_defects'))"
          :error-messages="currentTranslationErrors.defect"
          rows="1"
          outlined
          dense
          @change="changeTranslationErrors"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>

import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    defects: {
      type: String,
      required: false,
      default: null,
    },
    sizing: {
      type: String,
      required: false,
      default: null,
    },
    translations: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      required: true,
    },
    showTitle: Boolean,
  },
  data () {
    return {
      currentLanguage: 'default',
    }
  },
  computed: {
    languages () {
      let languages = ['en', 'it']
      for (const translation of this.translations) {
        if (languages.includes(translation.language)) {
          continue
        }
        languages.push(translation.language)
      }

      return languages
    },
    titleValue: {
      get () { return this.title  },
      set (value) { this.$emit('update:title', value) },
    },
    descriptionValue: {
      get () { return this.description  },
      set (value) { this.$emit('update:description', value) },
    },
    defectsValue: {
      get () { return this.defects },
      set (value) { this.$emit('update:defects', value) },
    },
    sizingValue: {
      get () { return this.sizing },
      set (value) { this.$emit('update:sizing', value) },
    },
    currentTranslationTitleValue: {
      get () {
        return this.translations.find(elt => elt.language === this.currentLanguage)?.title
      },
      set (value) {
        const translations = this.getClonesTranslations()
        const targetTranslation = translations.find(translation => translation.language === this.currentLanguage)
        if (targetTranslation) {
          targetTranslation.title = value
        } else {
          translations.push({
            language: this.currentLanguage,
            title: value,
          })
        }

        this.$emit('update:translations', translations)
      },
    },
    currentTranslationDescriptionValue: {
      get () {
        return this.translations.find(elt => elt.language === this.currentLanguage)?.description
      },
      set (value) {
        const translations = this.getClonesTranslations()
        const targetTranslation = translations.find(translation => translation.language === this.currentLanguage)
        if (targetTranslation) {
          targetTranslation.description = value
        } else {
          translations.push({
            language: this.currentLanguage,
            description: value,
          })
        }
        this.$emit('update:translations', translations)
      },
    },
    currentTranslationDefectsValue: {
      get () {
        return this.translations.find(elt => elt.language === this.currentLanguage)?.defect
      },
      set (value) {
        const translations = this.getClonesTranslations()
        const targetTranslation = translations.find(translation => translation.language === this.currentLanguage)
        if (targetTranslation) {
          targetTranslation.defect = value
        } else {
          translations.push({
            language: this.currentLanguage,
            defect: value,
          })
        }
        this.$emit('update:translations', translations)
      },
    },
    currentTranslationSizingValue: {
      get () {
        return this.translations.find(elt => elt.language === this.currentLanguage)?.sizing
      },
      set (value) {
        const translations = this.getClonesTranslations()
        const targetTranslation = translations.find(translation => translation.language === this.currentLanguage)
        if (targetTranslation) {
          targetTranslation.sizing = value
        } else {
          translations.push({
            language: this.currentLanguage,
            sizing: value,
          })
        }
        this.$emit('update:translations', translations)
      },
    },
    currentTranslationErrors () {
      const index = this.translations.findIndex(elemt => elemt.language === this.currentLanguage)
      if (-1 === index) {
        return {}
      }

      return this.errors.translations[index] ?? {}
    },
  },
  methods: {
    change() {
      const errors = this.errors
      errors.title = null
      errors.description = null
      errors.defects = null
      errors.sizing = null
      this.$emit('update:errors', errors)
    },
    changeTranslationErrors() {
      const errors = this.errors
      const index = this.translations.findIndex(elemt => elemt.language === this.currentLanguage)
      if (-1 === index) {
        return
      }
      errors.translations[index].title = null
      errors.translations[index].description = null
      errors.translations[index].defect = null
      errors.translations[index].sizing = null

      this.$emit('update:errors', errors)
    },
    getClonesTranslations() {
      return [
        ...this.translations
          .map(translation => { return { ...translation } })
          .filter(translation => (
            translation.title || translation.description || translation.defect || translation.sizing
          )),
      ]
    },
    hasError(language) {
      if (!language) {
        return (
          this.errors.title || this.errors.description || this.errors.defects || this.errors.sizing ||
          this.errors.translations?.filter(error => (
            error.title || error.description || error.defect || error.sizing
          )).length
        )
      }
      if (language === 'default') {
        return this.errors.title || this.errors.description || this.errors.defects || this.errors.sizing
      }
      const index = this.translations.findIndex(elemt => elemt.language === language)
      if (-1 === index) {
        return false
      }
      const error = this.errors.translations[index] ?? {}

      return error.title || error.description || error.defect || error.sizing
    },
  },
}
</script>

<template>
  <v-select
    v-model="stateValue"
    :items="status"
    :dense="dense"
    hide-details
    prepend-inner-icon="mdi-state-machine"
    label="Statut"
    clearable
    outlined
    small-chips
    chips
    item-text="label"
    item-value="value"
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-title>
          <StatusChip :status="item.value" />
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import StatusChip from '@/components/base/Drop/StatusChip.vue'

export default {
  name: 'StatusSelector',
  components: { StatusChip },
  props: {
    state: {
      type: String,
      default: null,
    },
    dense: Boolean,
  },
  data() {
    return {
      status: [
        {
          label: this.$i18n.t('status.drop.soon.label'),
          value:'SOON',
        }, {
          label: this.$i18n.t('status.drop.pre_live.label'),
          value:'PRE_LIVE',
        }, {
          label: this.$i18n.t('status.drop.live.label'),
          value:'LIVE',
        }, {
          label: this.$i18n.t('status.drop.ended.label'),
          value:'ENDED',
        }, {
          label: this.$i18n.t('status.drop.archived.label'),
          value:'ARCHIVED',
        },
      ],
    }
  },
  computed: {
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>

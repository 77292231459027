<template>
  <v-text-field
    v-model="stateValue"
    label="Mots clés (titre & description)"
    prepend-inner-icon="mdi-text-search-variant"
    hide-details
    clearable
    dense
    outlined
  />
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: null,
    },
  },
  computed: {
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

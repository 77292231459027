<template>
  <v-text-field
    v-model="rate"
    :label="inputLabel(this.$t('views.products.forms.input.discount_rate'), required)"
    :error-messages="error"
    suffix="%"
    type="number"
    hide-details
    clearable
    dense
    outlined
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  computed: {
    rate: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>

<template>
  <v-chip
    pill
  >
    {{ $t('views.products.forms.select_options.'+ target.toLowerCase()) }}
  </v-chip>
</template>
<script>

export default {
  name: 'TargetChip',
  props: {
    target: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>

<template>
  <v-chip
    class="ma-2"
    :color="statusColor(status)"
    text-color="white"
    small
  >
    {{ $t(`views.return_request.status.${status}`) }}
  </v-chip>
</template>
<script>
export default {
  name: 'ReturnStatusChip',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    statusColor (status) {
      if (status === 'pending') {
        return 'deep-orange darken-1'
      }
      if (status === 'to_validate') {
        return 'orange lighten-3'
      }

      if (status === 'to_close') {
        return 'orange lighten-1'
      }

      if (status === 'closed') {
        return 'green darken-1'
      }

      if (status === 'cancelled') {
        return 'grey lighten-3'
      }
    },
  },
}
</script>
<style scoped>

</style>

<template>
  <v-card
    class="mb-4"
  >
    <v-card-title v-if="showTitle">
      {{ $t('views.products.forms.titles.variants') }}
    </v-card-title>
    <v-card-text>
      <v-row
        v-for="(variant, index) in variants"
        :key="index"
        dense
        class="variant-line border-bottom-2 py-2"
      >
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-row dense>
            <v-col cols="12">
              <AttributeSelector
                v-model="variant.sizes"
                kind="size"
                :taxonomy-attributes="categoryTaxonomyAttributes"
              />
            </v-col>
            <v-col cols="6">
              <StockInput
                v-model="variant.stock"
                :error.sync="errorsData[index].stock"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="7"
          sm="12"
        >
          <v-row dense>
            <v-col
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <PriceInput
                v-model="variant.price"
                :error.sync="errorsData[index].price"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <BasePriceInput
                v-model="variant.basePrice"
                :error.sync="errorsData[index].base_price"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <DiscountRateInput
                v-if="updateMode"
                v-model="variant.discountRate"
                :error.sync="errorsData[index].discount_rate"
              />
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="variant.sku"
                hide-details
                dense
                outlined
                :label="$t('views.products.forms.input.sku')"
                disabled
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="variant.externalReference"
                hide-details
                clearable
                dense
                outlined
                :label="$t('views.products.forms.input.externalReference')"
                :error-messages="errorsData[index].external_reference"
              />
            </v-col>
            
            <v-col
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="variant.eanCode"
                hide-details
                clearable
                dense
                outlined
                :label="$t('views.products.forms.input.ean')"
                :error-messages="errorsData[index].eanCode"
                prepend-inner-icon="mdi-barcode"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            class="py-1"
          >
            <template v-for="(measure, index2) in variant.measures">
              <v-col
                :key="measure.slug"
                cols="12"
                md="3"
                sm="12"
                xs="12"
              >
                <!--
                        TODO: extract the measure name from the prop structure
                        BUG: we have to fail ove the label to the slug because
                        if a whole variant is updated without the category, the name will not
                        be added to the structure.
                        -->
                
                <MeasureInput
                  v-model="measure.value"
                  :label="measure.name ?? measure.slug"
                  :error.sync="errorsData[index].measures[index2].value"
                />
              </v-col>
            </template>
          </v-row>
        </v-col>
        
        <v-col
          cols="12"
          md="2"
          sm="12"
        >
          <v-list
            dense
            color="transparent"
            style="text-align: right"
          >
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  text
                  small
                  color="blue lighten-1"
                  @click="duplicateVariant(index)"
                >
                  <v-icon left>
                    mdi-content-duplicate
                  </v-icon>
                  Dupliquer
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="variants.length > 1">
              <v-list-item-title>
                <v-btn
                  text
                  small
                  color="red lighten-2"
                  @click="deleteVariant(index)"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Supprimer
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="blue lighten-1"
        text
        outlined
        @click="addVariant()"
      >
        <v-icon left>
          mdi-plus-circle
        </v-icon>
        {{ $t('views.products.forms.input.add_variation') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AttributeSelector from '@/components/base/Product/Input/AttributeSelector.vue'
import BasePriceInput from '@/components/base/Product/Input/BasePriceInput'
import DiscountRateInput from '@/components/base/Product/Input/DiscountRateInput'
import MeasureInput from '@/components/base/Product/Input/MeasureInput'
import PriceInput from '@/components/base/Product/Input/PriceInput'
import StockInput from '@/components/base/Product/Input/StockInput'

export default {
  components: { AttributeSelector, BasePriceInput, DiscountRateInput, StockInput, MeasureInput, PriceInput },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    categoryId: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    updateMode: Boolean,
    showTitle: Boolean,
  },
  data() {
    return {
      errorsData: this.errors,
      categoryTaxonomyAttributes: [],
      categorySupportedMeasures: [],
    }
  },
  computed: {
    variants: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
  watch: {
    errors(value) {
      this.errorsData = value
    },
    async categoryId() {
      this.categoryTaxonomyAttributes = []
      this.categorySupportedMeasures = []
      await this.updateCategoryTaxonomies()
      this.handleCategoryChange()
    },
    async target() {
      this.categoryTaxonomyAttributes = []
      this.categorySupportedMeasures = []
      await this.updateCategoryTaxonomies()
      this.handleCategoryChange()
    },
  },
  async mounted () {
    if (this.variants.length === 0) {
      await this.addVariant()
    }
    if (this.categoryId) {
      await this.updateCategoryTaxonomies()
      this.handleCategoryChange()
    }
  },
  methods: {
    handleCategoryChange() {
      // category has changed, time to merge the new measures without losing existing data
      this.errorsData = []
      this.variants.map((variant, index) => {
        if (!this.errorsData[index]) {
          this.errorsData[index]= {}
        }
        this.errorsData[index].measures = this.categorySupportedMeasures.map(() => ({ value: null }))
        const prevMeasures = variant.measures
        variant.measures = this.categorySupportedMeasures.map(measure => {
          const matchingMeasure = prevMeasures.find(m => m.slug === measure.slug)
          
          return {
            ...measure,
            value: matchingMeasure ? matchingMeasure.value : null,
          }
        })
      })
      this.$emit('update:errors', this.errorsData)
    },
    async updateCategoryTaxonomies() {
      if (
        !this.categoryId
        || this.categorySupportedMeasures.length > 0
        || this.categoryTaxonomyAttributes.length > 0
      ) {
        return
      }
      const response = await this.$axios.get('v3/categories/' + this.categoryId)
      this.categorySupportedMeasures = response.data.taxonomies
        .filter(taxonomy => taxonomy.kind === 'measure')
        .sort((t1, t2) => t1.priority - t2.priority)
        .map(measure => {
          return {
            name: measure.name, slug: measure.slug,
          }
        })

      this.categoryTaxonomyAttributes = response.data.taxonomy_attributes
    },
    async addVariant() {
      await this.updateCategoryTaxonomies()
      const variant = {
        externalReference: null,
        eanCode: null,
        sizes: [],
        stock: 1,
        basePrice: null,
        price: null,
        discountRate: null,
        sku: null,
        measures: this.categorySupportedMeasures.map(measure => {
          return {
            ...measure,
            value: null,
          }
        }),
      }
      this.variants.push(variant)
      this.errorsData.push({
        measures: this.categorySupportedMeasures.map(() => ({ value: null })),
      })
      this.$emit('update:errors', this.errorsData)
    },

    async duplicateVariant(index) {
      await this.updateCategoryTaxonomies()
      const variant = {
        externalReference: null,
        eanCode: null,
        sizes: [],
        stock: 1,
        basePrice: this.variants[index].basePrice,
        price: this.variants[index].price,
        discountRate: this.variants[index].discountRate,
        sku: null,
        measures: this.categorySupportedMeasures.map(measure => {
          return {
            ...measure,
            value: null,
          }
        }),
      }
      this.variants.push(variant)
      this.errorsData.push({
        measures: this.categorySupportedMeasures.map(() => ({ value: null })),
      })
      this.$emit('update:errors', this.errorsData)
    },
    
    deleteVariant(index) {
      this.variants.splice(index, 1)
      this.errorsData.splice(index, 1)
      this.$emit('update:errors', this.errorsData)
    },
  },
}
</script>

<style scoped>
.variant-line {
    border-bottom: #CCC 1px solid;
}

.variant-line:nth-child(even) {
    background: #FAFAFA
}
</style>

<template>
  <span>
    {{ store.name }}
    <a :href="`/stores/${store.id}`">
      <v-icon
        small
        color="blue lighten-1"
      >mdi-link</v-icon>
    </a>
  </span>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
}
</script>

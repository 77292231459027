<template>
  <v-autocomplete
    v-model="target"
    :deletable-chips="multiple"
    :error-messages="error"
    :items="targets"
    :label="inputLabel($t('views.taxonomy.forms.input.type_of_store'), required)"
    :multiple="multiple"
    :small-chips="multiple"
    clearable
    dense
    hide-details
    item-text="name"
    item-value="id"
    outlined
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'
export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    multiple: Boolean,
  },
  computed: {
    target: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
    targets () {
      return [{
        id: 'ALL',
        name: this.$t('views.taxonomy.enum.type_of_store.ALL'),
      }, {
        id: 'B2B',
        name: this.$t('views.taxonomy.enum.type_of_store.B2B'),
      }]
    },
  },
}
</script>

<template>
  <v-chip
    class="ma-2"
    text-color="white"
    small
    :class="getStatusColor(status)"
  >
    {{ $t('status.drop.' + status.toLowerCase() + '.label') }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status.toLowerCase()) {
      case 'not_published':
        return 'blue-grey lighten-4'
      case 'soon':
        return 'orange lighten-3'
      case 'pre_live':
        return 'teal lighten-4'
      case 'live':
        return 'teal lighten-2'
      case 'ended':
        return 'grey lighten-1'
      case 'archived':
        return 'black'
      }
      
      return ''
    },
  },
}
</script>

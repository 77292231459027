<template>
  <v-select
    v-model="stateValue"
    :items="values"
    :dense="dense"
    hide-details
    prepend-inner-icon="mdi-publish"
    label="Publié"
    clearable
    outlined
    small-chips
    chips
    item-text="label"
    item-value="value"
  />
</template>

<script>
export default {
  name: 'PublishedSelector',
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    dense: Boolean,
  },
  data() {
    return {
      values: [
        {
          label: 'Oui',
          value: true,
        },
        {
          label: 'Non',
          value: false,
        },
      ],
    }
  },
  computed: {
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>

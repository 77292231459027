<template>
  <v-dialog
    v-model="showGallery"
    fullscreen
  >
    <v-card>
      <v-toolbar>
        <div class="flex-grow-1" />
        <v-toolbar-items>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon left>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-slide-group
          v-model="selected"
          center-active
          show-arrows
        >
          <v-slide-item
            v-for="photo in productPhotos"
            :key="'photo-' + photo.id"
            :value="photo"
            class="pa-4"
          >
            <a
              :href="photo.photo.src.original"
              target="_blank"
            >
              <img :src="getImageGallerySrc(photo.photo)">
            </a>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProductPhotoGalleryDialog',
  props: {
    show: Boolean,
    productPhotos: {
      type: Array,
      default: () => [],
    },
    defaultPhotoId: {
      type: String,
      default: null,
    },
  },
  computed: {
    showGallery: {
      get () { return this.show },
      set (value) { this.$emit('update:show', value) },
    },
    selected () {
      return this.productPhotos.find(elt => this.defaultPhotoId  && this.defaultPhotoId === elt.photo.id)
    },
  },
  methods: {
    getImageGallerySrc(photo) {
      try {
        const url = new URL(photo.src.list)

        return url.href
      } catch (e) {
        return ''
      }
    },
  },
}
</script>

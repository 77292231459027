<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title>
        Editer une note
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-textarea
              v-model="textValue"
              class="py-4"
              label="Note"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NoteModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    noteId: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    openedValue: {
      get () { return this.opened },
      set (value) { this.$emit('update:opened', value) },
    },
    textValue: {
      get () { return this.text },
      set (value) { this.$emit('update:text', value) },
    },
  },
  methods: {
    close() {
      this.loading = false
      this.openedValue = false
    },
    submit() {
      this.$emit('submited', {
        text: this.textValue,
      })
      this.close()
    },
  },
}
</script>

<style scoped>

</style>

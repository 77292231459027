<template>
  <v-select
    v-model="stateValue"
    :dense="dense"
    :items="values"
    :label="$t('views.products.filters.in_imparfaite_warehouse')"
    clearable
    hide-details
    item-text="label"
    item-value="value"
    outlined
    prepend-inner-icon="mdi-warehouse"
  />
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: null,
    },
    dense: Boolean,
  },
  data() {
    return {
      values: [
        { label: this.$i18n.t('views.products.enum.in_imparfaite_warehouse.yes'),
          value: 'true' },
        { label: this.$i18n.t('views.products.enum.in_imparfaite_warehouse.no'),
          value: 'false' },
      ],
    }
  },
  computed: {
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>
